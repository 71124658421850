import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAddProductContext } from '../../../Contexts/AddProductContext';
import ButtonsIncDec from '../ButtonsIncDec';
import SlotStep from './SlotStep';
import { TbChevronLeft, TbMessage2Question, TbPhotoX } from 'react-icons/tb';
import Button from '../../Form/Button';
import ButtonPrimary from '../../Form/ButtonPrimary';
import { useCartContext } from '../../../Contexts/CartContext';
import { useLoginContext } from '../../../Contexts/LoginContext';
import ButtonOpenStoreCounter from '../ButtonOpenStoreCounter';
import DOMPurify from 'dompurify';
import Img from '../Img';
import { formatCurrency } from '../../../Utils/Formatters';
import { useOrderTypeContext } from '../../../Contexts/OrderTypeContext';
import CartService from '../../../Services/CartService';
import { useStoreContext } from '../../../Contexts/StoreContext';
import { ImSpinner8 } from 'react-icons/im';
import { PRODUCT_TYPE_COMPOSITE, PRODUCT_TYPE_SIMPLE } from '../../../Utils/Constants/Product';
import ProductPrice from './ProductPrice';
import ButtonClose from '../ButtonClose';
import { useLayoutContext } from '../../../Contexts/LayoutContext';
import Badge from '../Badge';

interface AddProductProps {
  product: any;
  category: any;
}

const AddProduct = ({
  product,
  category,
}: AddProductProps) => {

  const { setShowAddProduct } = useAddProductContext();
  const { token } = useLoginContext();
  const { addCartTempInConference, setAddProductCartTemp, cartTemp, cartDisplay, currentSteps, setCurrentSteps, requiredStepsValid, disabledButtonAddProduct, setDisabledButtonAddProduct, getPriceTotalSelectedComplements, cartFinished } = useCartContext();
  const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
  const [observation, setObservation] = useState<string | null>(null);
  const [height] = useState('45%');
  const [fixedTitle, setFixedTitle] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const description = DOMPurify.sanitize(product.description);
  const [currentProduct, setCurrentProduct] = useState<any>(product);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [loadingSteps, setLoadingSteps] = useState(product.type === PRODUCT_TYPE_COMPOSITE);
  const { currentStore } = useStoreContext();
  const { isMobile } = useLayoutContext();

  const handleScroll = () => {
    if (modalRef.current) {
      const boxImage = document.getElementById('boxImage');
      if (boxImage) {
        setFixedTitle(modalRef.current.scrollTop >= boxImage.offsetHeight)
      } else {
        setFixedTitle(modalRef.current.scrollTop >= 100)
      }
    }
  }

  const handleCancel = () => {
    setShowAddProduct(false)
    setTimeout(() => { //* timeout only for layout
      setObservation(null);
      setCurrentSteps([]);
    }, 200);
  }

  const handleAdd = () => {
    setAddProductCartTemp(currentProduct);
  }

  useEffect(() => {
    if (cartTemp.length > 0) {
      addCartTempInConference();
      setShowAddProduct(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartTemp]);

  const handleObservation = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newObservation = event.target.value
    setObservation(newObservation);
    setCurrentProduct({...currentProduct, observation: newObservation})
  };

  useEffect(() => {

    setCurrentProduct({...currentProduct, quantity: 1})
    setCurrentSteps([]);

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('scroll', handleScroll);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateProduct = (quantity: number) => {
    setCurrentProduct({...currentProduct, quantity: quantity})
  }

  useEffect(() => {
    const getStepsAndComplements = async () => {
      const payload = {
          store_id: currentStore.id,
          product_id: product.id,
          order_type: Number(getOrderTypeIdByName(orderType)),
      }
      try {
          const cartService = new CartService(orderType, token);
          const getSteps = await cartService.stepsAndComplements(payload);
          if (getSteps) {
            setCurrentSteps(getSteps.product.product_composite_steps);
          }
        } catch (error: any) {
          setCurrentSteps([]);
        } finally {
          setLoadingSteps(false);
        }
    }
    if (product.type === PRODUCT_TYPE_COMPOSITE) {
      getStepsAndComplements();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderTypeIdByName, orderType, product.id, currentStore.id, token])

  useEffect(() => {
    if (currentSteps) {
      setDisabledButtonAddProduct(loadingSteps || !requiredStepsValid())
      if (currentSteps.length > 0) {
        setCurrentPrice(getPriceTotalSelectedComplements())

        setCurrentProduct((prevProduct: any) => ({
          ...prevProduct,
          price: getPriceTotalSelectedComplements()
        }));

      } else {
        setCurrentPrice(product.price)
        setCurrentProduct((prevProduct: any) => ({
          ...prevProduct,
          price: product.price
        }));
      }
    } 
  }, [currentSteps, loadingSteps, requiredStepsValid, setDisabledButtonAddProduct, getPriceTotalSelectedComplements, product.price])


  return (
    <section className="flex flex-col justify-between max-h-dvh">

      <div className='flex flex-col gap-4 w-full overflow-y-auto h-dvh md:h-auto md:max-h-[80vh]' ref={modalRef} >

        <div className='flex h-full gap-4'>

          <div className='h-full md:flex w-full'>


            <div className={`inline md:flex md:flex-col h-full md:top-0 ${product.type === PRODUCT_TYPE_COMPOSITE ? 'md:self-start md:w-3/6 md:sticky' : 'w-full'}`}>

              {(fixedTitle || product.photos) &&
                <button
                    type="button"
                    onClick={handleCancel}
                    className="md:hidden absolute z-20 left-2 top-3 p-2 bg-black bg-opacity-60 hover:bg-opacity-50 rounded-full transition-color ease-in-out duration-200">
                    <TbChevronLeft size={22} className='text-white' />
                </button>
              }
              {product.photos?.thumbnail 
                ? <div id="boxImage" className="md:hidden z-10 sticky top-0 block w-full bg-no-repeat bg-fixed bg-cover bg-center bg-gray-50" style={{height, backgroundImage: `url('${product.photos.medium}')`}}></div>
                : <Img src={product.photos?.medium} fallback={
                                  <div className='md:hidden flex items-center justify-center w-full h-full bg-gray-50' style={{height}}>
                                  <TbPhotoX size={50} className='text-gray-200' /></div>
                                  } alt={product.name} className="w-full" />
              }


              {/* Title Top (desktop) */}
              <div className='hidden md:flex md:flex-col md:flex-grow w-full text-left'>
                <div className='flex h-[45vh] max-h-fit justify-center p-2'>

                  {product.type === PRODUCT_TYPE_COMPOSITE &&
                    <div className={`
                        flex items-center w-full justify-center md:p-1.5 rounded
                        ${!product.photos.medium && 'bg-no-repeat bg-cover bg-center bg-gray-50 min-h-[25vh]'}
                      `}>
                      {!product.photos.medium && <TbPhotoX size={50} className='text-gray-200' />}
                      {product.photos.medium && <Img src={product.photos?.thumbnail} alt={product.name} className="w-full md:rounded-md" />}
                    </div>
                  }

                  {product.type === PRODUCT_TYPE_SIMPLE &&
                    <div className='
                      flex items-center w-full justify-center md:p-1.5
                      min-h-[45vh]
                      rounded
                      bg-no-repeat bg-cover bg-center
                      bg-gray-50
                    '
                      style={{height, backgroundImage: `url('${product.photos.medium}')`}}
                    >
                      {!product.photos.medium && <TbPhotoX size={50} className='text-gray-200' />}
                    </div>
                  }

                </div>
                <div className="flex flex-col w-full flex-1 h-auto gap-2 p-4 text-left ">
                  <h2 className='text-2xl font-bold text-custom-primary'>
                    {product.name}
                  </h2>
                  <div className='text-sm text-nowrap'>
                    <ProductPrice product={product} />
                  </div>
                  <div className='text-sm leading-5 text-gray-500 mb-2' dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            </div>






            <div className={`md:overflow-y-auto md:w-full bg-white sticky z-20 ${product.type !== PRODUCT_TYPE_COMPOSITE && 'md:hidden'}`}>
              <section className='relative p-1 pt-2 md:p-4 h-full flex flex-col'>

                {/* Header/Title Side (mobile) */}
                <header className='md:hidden'>
                  <div className='grid gap-1 w-full text-left p-2'>
                    <h2 className={
                      `transition-all ease-in-out duration-200
                      flex gap-1 items-center text-gray-700
                      font-extrabold
                      ${fixedTitle ? 'fixed top-0 h-16 text-xl left-0 z-50 py-4 pl-1 pr-6 w-full bg-white ring-1 ring-gray-100 cursor-pointer' : 'text-xl'}`
                      }
                      onClick={() => fixedTitle ? handleCancel() : false}
                      >
                        {fixedTitle && 
                          <button
                            type="button"
                            onClick={handleCancel}
                            className="p-1 hover:bg-gray-100 rounded-full transition-color ease-in-out duration-200">
                            <TbChevronLeft size={22} />
                        </button>
                        }
                        <span className={`text-custom-primary ${fixedTitle && 'leading-5'}`}>{product.name}</span>
                    </h2>
                    <div className='text-nowrap'>
                      <ProductPrice product={product} />
                    </div>
                  </div>

                  <div className='text-left leading-5 font-medium text-gray-500 p-2 pt-0 mb-2 md:mb-0' dangerouslySetInnerHTML={{ __html: description }} />
                </header>

                {product.type === PRODUCT_TYPE_COMPOSITE &&
                  <div className={`flex flex-col gap-4 md:h-full ${loadingSteps && 'overflow-hidden'}`}>

                    {loadingSteps
                      ?
                        <div className="flex items-center md:justify-center gap-1 min-h-full text-sm text-gray-500 p-3 pt-6">
                          <ImSpinner8 className="animate-spin" />
                          <span>Carregando complementos</span>
                        </div>
                      :
                        currentSteps.map((step: any, index: number) =>
                          <SlotStep product={product} step={step} key={index} />
                        )
                    }

                    {cartDisplay && !loadingSteps &&
                      <div className="flex flex-col gap-1 p-3 w-full">
                        <h4 className='flex items-center gap-0.5 font-semibold text-sm text-custom-primary'>
                          <TbMessage2Question />
                          <span>Alguma observação?</span>
                        </h4>
                        <textarea
                          id="observation"
                          name="observation"
                          className={`
                            block w-full rounded-md text-sm border-0 p-2.5 shadow ring-1 ring-gray-300 placeholder:text-gray-300 
                            focus:ring-2 focus:ring-custom-primary
                            transition-all ease-in-out duration-500
                            ${observation ? 'h-24' : 'h-16'}
                            focus:h-24
                            `}
                          placeholder='Ex: Tirar cebola, maionese à parte, ponto da carne etc'
                          defaultValue={''}
                          onChange={handleObservation}
                        />
                      </div>
                    }
                  </div>
                }

              </section>
            </div>








          </div>

        </div>
      </div>

      {/* Footer buttons */}
      <div className="flex gap-2 w-full p-4 md:justify-end text-sm">
        {!isMobile && product.type === PRODUCT_TYPE_SIMPLE && <ButtonClose onClick={() => handleCancel()} />}
        {product.type === PRODUCT_TYPE_COMPOSITE && <Button onClick={handleCancel} className='hidden md:flex items-center'>Cancelar</Button>}

        {!cartDisplay ?
          <ButtonOpenStoreCounter 
            label={cartFinished 
                    ? <div className='flex items-center gap-1 w-full justify-between'><Badge type='success' outline className='h-5 ring-2 rounded-full font-extrabold text-sm'>Pago</Badge> Veja seu comprovante</div>
                    : undefined
            } />
        :
          <>
            <div className='hidden md:block w-2/5' />
            <ButtonsIncDec disabled={loadingSteps} onInc={handleUpdateProduct} onDec={handleUpdateProduct} className='w-44 md:w-auto md:min-w-40' />
            <ButtonPrimary
                onClick={handleAdd}
                disabled={disabledButtonAddProduct}
                className="
                    flex w-full md:w-auto md:min-w-72 text-nowrap gap-2 md:gap-6
                    justify-between items-center
                ">
                <span>Adicionar</span>
                <span>{ formatCurrency(currentProduct.quantity * currentPrice) }</span>
            </ButtonPrimary>
          </>
        }

      </div>

    </section>
  )
}

export default AddProduct;