import React, { useEffect } from "react";
import Home from "./Pages/Home";

import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
// import Lojas from "./Pages/Lojas";
import Drawer from "./Components/Layout/Drawer";
import Cart from "./Components/Layout/Cart/Cart";
import { useCartContext } from "./Contexts/CartContext";
import { useOrderTypeContext } from "./Contexts/OrderTypeContext";
import { useLoginContext } from "./Contexts/LoginContext";
import Modal from "./Components/Layout/Modal";
import ServicesChoice from "./Components/Layout/ServicesChoice";
import Checkout from "./Pages/Checkout";
import LoginBasic from "./Components/Layout/LoginBasic";
import CartConference from "./Components/Layout/Cart/CartConference";
import { useLayoutContext } from "./Contexts/LayoutContext";
import { TbHash } from "react-icons/tb";
import { useStoreContext } from "./Contexts/StoreContext";
import Badge from "./Components/Layout/Badge";
import CartStoreCounter from "./Components/Layout/Cart/CartStoreCounter";
import LoadingMain from "./Components/Layout/LoadingMain";
import { formatCartId } from "./Utils/Formatters";
import CompanyService from "./Services/CompanyService";
import Offline from "./Components/Layout/Offine";
import ProofPayment from "./Components/Layout/Cart/ProofPayment";
import { useCartHook } from "./Hooks/CartHook";
import { useStoreHook } from "./Hooks/StoreHook";
import { MdTableRestaurant } from "react-icons/md";

function App() {

  const {mainLoading, company, setCompany} = useLayoutContext();
  const {cartConference, cartDisplay, showCartIsFinished, setShowCartIsFinished, cartFinished } = useCartContext();
  const {user, token} = useLoginContext();
  const {setLoadingStore, cashier} = useStoreContext();
  const {getOpenedCustomerCartDiffStored} = useCartHook();

  const data = {
    bannersList: [
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/4280f6f4ac221260710776f731e7efba.png'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/4280f6f4ac221260710776f731e7efba.png'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/93633d79bb6430a77e171df4fac612aa.jpg'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/4a8dda47c7badebf072757577907e67d.png'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/7837f2b23a3b36f1f8e1e4560cfc61e2.jpg'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/3b0658a1e2f58c258a9c36189e5d0010.png'},
      {name: '', href: '#', img: 'https://storage.shopfood.io/public/companies/poe726g0/cms_banners/d7fe524d7389e2c7d29b544669474f7b.png'},
    ],
  }

  const { showCart, setShowCart, showCartConference, setShowCartConference } = useCartContext();
  const { orderType, showOrderTypeChoice, setShowOrderTypeChoice, getOrderTypeIdByName } = useOrderTypeContext();
  const { showLogin, setShowLogin, closeableLogin } = useLoginContext();
  const { isMobile } = useLayoutContext();
  const { currentStore, setTable, checkStoreOpen } = useStoreContext();
  const { loadStoreInfo } = useStoreHook();


  useEffect(() => {
    const loadCompanyInfo = async () => {
      try {
          const companyService = new CompanyService(orderType);
          const companyInfo = await companyService.info();
          if (companyInfo) {
            setCompany(companyInfo)
            applyColorsToTailwind(companyInfo.styles)
          }
      } catch (error) {
          console.error('Erro companyInfo:', error);
      }
    }
    loadCompanyInfo();

    const applyColorsToTailwind = (colors: any) => {
      const root = document.documentElement;
      Object.keys(colors).forEach(key => {
        root.style.setProperty(`--color-${key}`, colors[key]);
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  useEffect(() => {
    const getQueryStringValue = (key: string) => {
      const params = new URLSearchParams(window.location.search);
      return params.get(key);
    }

    const storeIdByQuery = getQueryStringValue('store_id') || '';
    const storeTableIdByQuery = getQueryStringValue('store_table_id') || '';

    if (!cartDisplay && storeTableIdByQuery) {
      setTable(storeTableIdByQuery)
    }

    if (!currentStore || !checkStoreOpen(Number(getOrderTypeIdByName(orderType)))) {
      if (storeIdByQuery) {
        loadStoreInfo(parseInt(storeIdByQuery));
      } else {
        setLoadingStore(false);
      }
    } else if (currentStore && !cashier) {
      loadStoreInfo(currentStore.id);
    }


    //* Verificando se a comanda do localStorage é diferente da aberta e substuindo
    if (token && cartDisplay) {
      getOpenedCustomerCartDiffStored(token);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //* Alterando o favicon do projeto com base na imagem da Company
    if (company && company.square_image) {
      const link = document.querySelector("link[rel='icon']") as HTMLLinkElement || document.createElement('link');
      link.rel = 'icon';
      link.href = company.square_image;
      document.head.appendChild(link);
    }
  }, [company]);

  return (
    <>
      <Offline />
      <BrowserRouter>

        <Modal open={mainLoading} className="max-w-xs m-4" closeable={false} onClose={() => {}}>
          <LoadingMain />
        </Modal>

        <Routes>
          <Route path="/" element={
            <Home
              bannersList={data.bannersList}
              loading={mainLoading}
            />
          } />
          <Route path="/auto-atendimento" element={
            <Home
              bannersList={data.bannersList}
              loading={mainLoading}
            />
          } />
          {/* <Route path="/lojas" element={
            <Lojas
              categories={data.categories}
              />
          } /> */}
          <Route path="/checkout" element={
            <Checkout />
          } />
        </Routes>

        {/* DRAWER CART */}
        <Drawer open={showCart} 
          title={`${user?.name}`} 
          subtitle={cartDisplay && <div className="flex gap-1"><Badge type="custom" className="text-sm md:text-xs"><TbHash />{ formatCartId(cartDisplay.id) }</Badge> <Badge type="custom" outline className="text-sm md:text-xs gap-0.5"><MdTableRestaurant />{cartDisplay.table.name}</Badge></div>} 
          fromBottom={isMobile} onClose={() => setShowCart(false)}
          >
          {orderType === 'store_counter'
            ? <CartStoreCounter onCloseCart={() => setShowCart(false)} />
            : <Cart cart={[]} onCloseCart={() => setShowCart(false)} />
          }
        </Drawer>

        {/* DRAWER CART CONFERENCE */}
        <Drawer 
          open={showCartConference} title={`${user?.first_name}, confira seu pedido`} 
          subtitle={cartDisplay && <div className="flex gap-1"><Badge type="custom" className="text-sm md:text-xs"><TbHash />{ formatCartId(cartDisplay.id) }</Badge> <Badge type="custom" outline className="text-sm md:text-xs gap-0.5"><MdTableRestaurant />{cartDisplay.table.name}</Badge></div>} 
          fromBottom={isMobile} 
          onClose={() => setShowCartConference(false)}
          >
            <CartConference cart={cartConference} onCloseCart={() => setShowCartConference(false)} />
        </Drawer>

      </BrowserRouter>

      {/* MODAL SERVICE CHOICE */}
      <Modal open={showOrderTypeChoice} className="max-w-2xl m-4" onClose={() => setShowOrderTypeChoice(false)}>
          <ServicesChoice onClose={() => setShowOrderTypeChoice(false)} />
      </Modal>

      {/* MODAL LOGIN */}
      <Modal open={showLogin} className="max-w-sm m-4" onClose={() => setShowLogin(false)} closeable={closeableLogin}>
          <LoginBasic onClose={() => setShowLogin(false)} closeable={closeableLogin} />
      </Modal>

      {/* CART FINISHED */}
      {cartFinished &&
        <Drawer open={showCartIsFinished} 
          title='Seu comprovante'
          fromBottom={isMobile} onClose={() => setShowCartIsFinished(false)}
          >
          <ProofPayment
            cart_id={cartFinished.order.cart.id} 
            payment_method={cartFinished.order.payment_method} 
            payment_methods={cartFinished.order.payment_methods} 
            order_id={cartFinished.order.id} 
            showButtonOpenNewCart={!cartDisplay}
            cashier_id={cartFinished.cashier_id}
          />
        </Drawer>
      }

    </>
  );
}

export default App;