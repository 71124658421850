import React, { useEffect, useState } from "react";
import Cart from "../Components/Layout/Cart/Cart";
import CartEmpty from '../Components/Layout/Cart/CartEmpty';
import HeaderMinimal from "../Components/Layout/HeaderMinimal";
import { TbBuildingSkyscraper, TbCircleCheckFilled, TbClock, TbId, TbMap, TbMapPinFilled, TbMessage, TbPencil } from "react-icons/tb";
import ButtonsCheckout from "../Components/Layout/Cart/ButtonsCheckout";
import Button from "../Components/Form/Button";
import { useStoreContext } from "../Contexts/StoreContext";
import { useOrderTypeContext } from "../Contexts/OrderTypeContext";
import CartService from "../Services/CartService";
import { useLoginContext } from "../Contexts/LoginContext";
import { ImSpinner8 } from "react-icons/im";
import CartStoreCounter from "../Components/Layout/Cart/CartStoreCounter";
import { IMaskInput } from "react-imask";
import { isCpfValid, vibrate } from '../Utils/Helpers'
import { formatCpf } from "../Utils/Formatters";
// import CustomerService from "../Services/CustomerService";
import ButtonMethodPayment from "../Components/Form/ButtonMethodPayment";
import { useLayoutContext } from "../Contexts/LayoutContext";
import FormRegisterCustomerCard from "../Components/Form/FormRegisterCustomerCard";
import QRCodePix from "../Components/Layout/Checkout/QRCodePix";
import { METHOD_PAYMENT_TYPE_CARD_ONLINE, METHOD_PAYMENT_TYPE_PIX, ORDER_STATUS_AWAITING_PAYMENT, ORDER_STATUS_DONE } from "../Utils/Constants/Checkout";
import StoreService from "../Services/StoreService";
import OrderService from "../Services/OrderService";
import { useCheckoutContext } from "../Contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../Contexts/CartContext";
import Modal from "../Components/Layout/Modal";
import Confirm from "../Components/Layout/Confirm";
import { CART_STATUS_FINISHED } from "../Utils/Constants/Cart";
import { useCartHook } from "../Hooks/CartHook";
import { useStoreHook } from "../Hooks/StoreHook";

const Checkout = () => {

    const [paymentMethod, setPaymentMethod] = useState<any>(null)
    const [showStoreAddress, setShowStoreAddress] = useState<boolean>(false)

    // const [showUpdateCustomer, setShowUpdateCustomer] = useState<boolean>(false)

    const { setMainLoading, setMainLoadingIcon, setMainLoadingIconError, setMainLoadingTitle, setMainLoadingMessage, setMainLoadingCloseButton } = useLayoutContext();
    const { showPixCode, setShowPixCode } = useCheckoutContext();

    const [observation, setObservation] = useState('')
    const [showInputObservation, setShowInputObservation] = useState(false)

    const [cpf, setCpf] = useState('')
    const [showInputCpf, setShowInputCpf] = useState(false)

    const [cart, setCart] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    // const [listAllPaymentMethods, setListAllPaymentMethods] = useState<any>(null);
    const [listPaymentMethods, setListPaymentMethods] = useState<any>(null);
    const [methodCardOnline, setMethodCardOnline] = useState<any>(null);
    const [loadingListPaymentMethods, setLoadingListPaymentMethods] = useState<boolean>(true)
    // const [loadingGetCards, setLoadingGetCards] = useState<boolean>(true)
    const [labelBtnPay, setLabelBtnPay] = useState<string>('')

    // const [customerCards, setCustomerCards] = useState<any>([])
    const [currentCard, setCurrentCard] = useState<any>(null)
    const [showPrePayd, setShowPrePayd] = useState<boolean>(false)
    const [cashier, setCashier] = useState<any | null>(null);
    const { currentStore } = useStoreContext();
    const { orderType, getOrderTypeIdByName } = useOrderTypeContext();
    const { token } = useLoginContext();
    const { order, setOrder } = useCheckoutContext();
    const { cartDisplay, setReturnToCheckout } = useCartContext();

    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const { cartFinished, cancelPayment } = useCartHook();
    const { getCashier, noOpenCashier } = useStoreHook();

    const navigate = useNavigate();

    useEffect(() => {
        if (!cartDisplay) {
            navigate('/');
        }
        setMethodCardOnline({type: METHOD_PAYMENT_TYPE_CARD_ONLINE})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (listPaymentMethods && listPaymentMethods.length === 1) {
            handleChangeMethod(listPaymentMethods[0]);
        }
    }, [listPaymentMethods]);

    const handleSetCard = (data: any) => {
        setCurrentCard(data);
    }

    useEffect(() => {
        if (currentCard && paymentMethod) {
            handleCreateOrder();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCard, paymentMethod])

    const handleObservation = () => {
        setShowInputObservation(false)
    }

    const handleCpf = () => {
        setShowInputCpf(false);
    }

    const handleCreateOrder = async () => {
        const payload = {
            payment_method_id: paymentMethod.id,
            payment_method: paymentMethod.type,
            store_id: currentStore.id,
            order_type: Number(getOrderTypeIdByName(orderType)),
            cashier_id: cashier.id,
            cart_id: cartDisplay.id,
            ...(cartDisplay.order?.id ? { order_id: cartDisplay.order.id } : {}) //* utilizado após o cancelamento do pagamento, no segundo createOrder
        }
        if (paymentMethod.type === METHOD_PAYMENT_TYPE_PIX) {
            setMainLoadingTitle('Gerando código PIX');
            createOrder(payload);
        } else if (paymentMethod.type === METHOD_PAYMENT_TYPE_CARD_ONLINE) {
            setMainLoadingTitle('Efetuando pagamento');
            //* incluindo pagamento dados do cartão no payload (removendo payment_method_id)
            const { payment_method_id, ...restPayload } = payload;
            const payloadUpdated = ({
                ...restPayload,
                ...currentCard
            });
            createOrder(payloadUpdated);
        }
    }

    const createOrder = async (payload: any) => {

        setMainLoading(true);
        setMainLoadingMessage('Aguarde...');

        try {
            const orderService = new OrderService(orderType, token);
            const orderCreated = await orderService.create(payload);

            if (orderCreated) {
                setOrder(orderCreated);
                setMainLoading(false);
                setShowRegisterCard(false);
                handleChangeMethod(null);
                if (orderCreated.payment_method.type === METHOD_PAYMENT_TYPE_PIX && orderCreated.status === ORDER_STATUS_AWAITING_PAYMENT) {
                    setShowPixCode(true);
                    setMainLoading(false);
                } else {
                    cartFinished({id: cartDisplay.id, order: orderCreated});
                    handleOrderFinished();
                }
            }
          } catch (error) {
            setMainLoadingIcon(false);
            setMainLoadingIconError(true);
            setMainLoadingTitle('Ops! Algo não deu certo.');
            setMainLoadingMessage('Tente novamente e, caso persista, chame o garçom.');
            setMainLoadingCloseButton(true);
          } 
    }

    
    useEffect(() => {

        if (order) {
            var intervalToCheckOrderStatus: NodeJS.Timeout | null = null;

            const getDetail = async () => {
                const payload = {
                    order_id: order.id,
                }
                try {
                    const orderService = new OrderService(orderType, token);
                    const details = await orderService.detail(payload);

                    if (details) {
                        if (details.status === ORDER_STATUS_AWAITING_PAYMENT) {
                            if (!intervalToCheckOrderStatus) {
                                intervalToCheckOrderStatus = setInterval(() => {
                                    getDetail();
                                }, 10000);
                            }
                        } else if (details.status === ORDER_STATUS_DONE) {
                            if (intervalToCheckOrderStatus) {
                                clearInterval(intervalToCheckOrderStatus);
                                intervalToCheckOrderStatus = null;
                            }
                            cartFinished({id: cartDisplay.id, order: details})
                            handleOrderFinished();
                        }
                    }
                } catch (error) {
                    console.error('Error order detail:', error);
                }
            };

            getDetail();

            if (order.payment_method.type === METHOD_PAYMENT_TYPE_PIX && order.status === ORDER_STATUS_AWAITING_PAYMENT) {
                setShowPixCode(true);
            }

            // Cleanup para limpar o intervalo ao desmontar o componente ou alterar a ordem
            return () => {
                if (intervalToCheckOrderStatus) {
                    clearInterval(intervalToCheckOrderStatus);
                }
            };
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        document.title = currentStore?.name;

        const showCart = async () => {
            const payload = {
                cart_id: cartDisplay?.id,
                store_id: currentStore.id,
                order_type: Number(getOrderTypeIdByName(orderType)),
            }

            try {
                const cartService = new CartService(orderType, token);
                const getCart = await cartService.show(payload);

                if (getCart) {
                    if (getCart.status === CART_STATUS_FINISHED && order?.status === ORDER_STATUS_DONE) {
                        cartFinished({id: cartDisplay.id, order: getCart.order});
                        setLoading(false);
                        handleCancel();
                    } else {
                        setCart(getCart);
                    }
                }
            } catch (error: any) {
                setCart(null)
            } finally {
                setLoading(false)
            }
        }
        showCart();

        getCashier().then((response) => {
            if (response) {
                setCashier(response);
            } else {
                noOpenCashier();
                handleCancel();
            }
        });

        const loadListPaymentMethods = async () => {
            setLoadingListPaymentMethods(true)
            try {
                const storeSevice = new StoreService(orderType);
                const payload = {
                    store_id: currentStore.id,
                }
                const data = await storeSevice.listPaymentMethods(payload);
                if (Array.isArray(data)) {
                    setShowPrePayd(data.some((method: any) => method.type === METHOD_PAYMENT_TYPE_CARD_ONLINE && method.pdv === true));
                    const paymentMethodsForStoreCounter = data.filter((method: any) => method.type === METHOD_PAYMENT_TYPE_PIX && method.pdv);
                    if (paymentMethodsForStoreCounter) {
                        setListPaymentMethods(paymentMethodsForStoreCounter)
                    }
                }
            } catch (error) {
                console.error('Erro listPaymentMethods:', error);
            } finally {
                setLoadingListPaymentMethods(false)
            }
        }

        // const getCards = async () => {
        //     const payload = {
        //         store_id: currentStore.id,
        //     }
        //     try {
        //         const customerService = new CustomerService(orderType, token);
        //         const getCards = await customerService.getCards(payload);
        //         if (getCards) {
        //             setCustomerCards(getCards.cards)
        //         }
        //         } catch (error) {
        //             console.error('Error show:', error);
        //             setCustomerCards(null)
        //         } finally {
        //             setLoadingGetCards(false)
        //         }
        // }

        // showCart();
        // getCards();
        loadListPaymentMethods();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paymentMethod) {
            setLabelBtnPay(paymentMethod && paymentMethod.type === METHOD_PAYMENT_TYPE_PIX ? 'Gerar código PIX' : 'Efetuar pagamento');
        } else {
            setLabelBtnPay('Escolha como pagar');
        }
    }, [paymentMethod])

    const handleCancel = () => {
        navigate('/')
        setReturnToCheckout(false)
    }

    const handleCancelCpf = () => {
        setShowInputCpf(false)
        setCpf('')
    }

    const [showRegisterCard, setShowRegisterCard] = useState(false)

    const handleCancelObservation = () => {
        setShowInputObservation(false)
    }

    const handleChangeMethod = (method: any) => {
        vibrate();
        setShowRegisterCard(false);
        setPaymentMethod(method);
        setCurrentCard(null);
    }

    const handleChangeMethodCard = () => {
        setShowRegisterCard(true);
        setPaymentMethod(methodCardOnline);
        vibrate();
    }

    const handleOrderFinished = () => {
        setMainLoading(false);
        setShowPixCode(false);
        navigate('/');
    }

    // const getImageBrandByBrand = (brand: string) => {
    //     const method = listAllPaymentMethods.find((method: any) => method.slug === brand);
    //     return method ? method.image_url : null;
    // }

    const handleExpirePix = () => {
        setMainLoading(true);
        setMainLoadingIcon(false);
        setMainLoadingIconError(true);
        setMainLoadingTitle('Seu código expirou.');
        setMainLoadingMessage('Será necessário gerar novamente');
        setMainLoadingCloseButton(true);
        cancelPayment();
    }

    return (
        <>
            <section className='flex flex-col flex-grow w-full h-dvh'> 
                {loading
                    ?
                        <>
                            <HeaderMinimal title="Pagamento" subtitle="Carregando..." />
                            <div className="flex items-center justify-center gap-1 h-full text-sm text-gray-500">
                                <ImSpinner8 className="animate-spin" /> 
                                <span>{orderType === 'store_counter' ? 'Carregando comanda' : 'Carregando pedido'}</span>
                            </div>
                        </>
                    :
                        !cart || (cart.items?.length === 0 && !showPixCode)
                            ?
                            <>
                                <CartEmpty />
                            </>
                            :
                                <div className="relative flex flex-col md:flex-row justify-between h-full">
                                    <section className="flex flex-col w-full md:overflow-y-auto">
                                        <div className=" sticky top-[-1px] z-30">
                                            <HeaderMinimal title="Pagamento" subtitle={orderType === 'store_counter' ? 'Finalize sua comanda' : 'Finalize seu pedido'} />
                                        </div>

                                        <div className="flex flex-col justify-between h-full gap-4 p-4 text-sm">

                                            <section className="flex flex-col gap-2 w-full h-full">

                                                {/* STORE */}
                                                {orderType !== 'store_counter' &&
                                                    <section className="flex gap-2 w-full justify-between items-center rounded-lg p-2 px-3 bg-gray-50 shadow-sm">
                                                        <div className="flex gap-2 items-center">
                                                            <TbBuildingSkyscraper size={20} className="self-start" />
                                                            <div className="flex flex-col">
                                                                <span className="text-gray-400">Restaurante</span>
                                                                <span className="font-semibold flex justify-between gap-2">
                                                                    <span>Bob's Praia de Botafogo</span>
                                                                    <button onClick={() => setShowStoreAddress(!showStoreAddress)} className="inline-flex items-center gap-0.5 text-xs font-medium text-custom-primary">
                                                                        <TbMap /> {showStoreAddress ? <>Ocultar endereço</> : <>Exibir endereço</> }
                                                                    </button>
                                                                </span>
                                                                <span className={`transition-all ease-in-out duration-200 text-gray-500 ${showStoreAddress ? 'opacity-100 h-auto' : 'opacity-0 h-0'}`}>Praia Botafogo, 360, Botafogo, Rio de Janeiro - RJ - CEP: 22250-040</span>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }

                                                {/* DELIVERY ADDRESS */}
                                                {orderType !== 'store_counter' &&
                                                    <section className="flex gap-2 w-full items-center rounded-lg p-2 px-3 bg-gray-50 shadow-sm">
                                                        <div className="flex gap-2 items-center w-full">
                                                            <TbMapPinFilled size={20} className="self-start" />
                                                            <div className="flex flex-col w-full">
                                                                <span className="text-gray-400">Endereço de entrega</span>
                                                                <span className="font-semibold">Praia de Botafogo, 300, Rio de Janeiro - RJ - CEP 22250-040</span>
                                                            </div>
                                                        </div>
                                                        <section className="flex gap-2">
                                                            <div className="flex flex-col">
                                                                <span className="text-gray-400 text-nowrap text-right">Est. entrega</span>
                                                                <span className="flex items-center gap-0.5 font-semibold text-nowrap justify-end">
                                                                    <TbClock /> 50 Minutos
                                                                </span>
                                                            </div>
                                                        </section>
                                                    </section>
                                                }


                                                {/* PAYMENT METHOD */}
                                                <div className={`flex flex-col gap-4 w-full`}>
                                                    {/* {loadingGetCards 
                                                        ? 
                                                            <div className="flex items-center gap-1">
                                                                <ImSpinner8 className="animate-spin" /> 
                                                                <span>Carregando cartões</span>
                                                            </div>
                                                        : */}
                                                        {
                                                        <>
                                                            {!showPixCode && 
                                                                <>
                                                                    {loadingListPaymentMethods && 
                                                                        <div className="flex items-center justify-start gap-1 h-full text-gray-500">
                                                                            <ImSpinner8 className="animate-spin" /> 
                                                                            <span>Carregando formas de pagamento...</span>
                                                                        </div>
                                                                    }

                                                                    <nav className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full mb-3">
                                                                        {(showPrePayd) &&
                                                                            <>
                                                                                <ButtonMethodPayment
                                                                                    payCard
                                                                                    label="Pagar com"
                                                                                    title="Cartão"
                                                                                    active={paymentMethod?.type === methodCardOnline.type}
                                                                                    disabled={showRegisterCard}
                                                                                    onClick={handleChangeMethodCard} 
                                                                                    className="relative"
                                                                                    loading={loadingListPaymentMethods}
                                                                                >
                                                                                    {showRegisterCard && <TbCircleCheckFilled size={20} className='absolute -right-1.5 -top-1.5 bg-white rounded-full text-custom-primary zoomInIconNoDelay' />}
                                                                                </ButtonMethodPayment>


                                                                                {/* {customerCards.map((card: any, index: number) => 
                                                                                    <ButtonMethodPayment
                                                                                        label="Utilizar seu cartão"
                                                                                        title={card.brand}
                                                                                        image_url={getImageBrandByBrand(card.brand)} 
                                                                                        active={card.id === currentCard?.id}
                                                                                        disabled={showRegisterCard}
                                                                                        onClick={() => handleChangeMethod(card)} 
                                                                                        className="relative"
                                                                                        loading={loadingListPaymentMethods}
                                                                                        number={card.masked_number.slice(-4)}
                                                                                        main={card.main}
                                                                                        key={index}
                                                                                    >
                                                                                        {card.id === currentCard?.id && <TbCircleCheckFilled size={20} className='absolute -right-1.5 -top-1.5 bg-white rounded-full text-custom-primary' />}
                                                                                    </ButtonMethodPayment>
                                                                                ) } */}
                                                                            </>
                                                                        }

                                                                        {!loadingListPaymentMethods && listPaymentMethods?.map((method: any, index: number) =>
                                                                            <ButtonMethodPayment 
                                                                                onClick={() => handleChangeMethod(method)} 
                                                                                label='Pagar com'
                                                                                title={method.name}
                                                                                image_url={method.image_url ?? ''} 
                                                                                active={paymentMethod?.type === method.type}
                                                                                disabled={paymentMethod?.type === method.type}
                                                                                key={index}
                                                                            >
                                                                                {paymentMethod?.type === method.type && <TbCircleCheckFilled size={20} className='absolute -right-1.5 -top-1.5 bg-white rounded-full text-custom-primary zoomInIconNoDelay' />}
                                                                            </ButtonMethodPayment>
                                                                        )}
                                                                    </nav>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                {showPixCode && <QRCodePix order={order} onClose={() => setShowPixCode(false)} onExpire={() => handleExpirePix} />}

                                                {showRegisterCard && 
                                                    <FormRegisterCustomerCard 
                                                        onCancel={() => { setShowConfirmCancel(true) }}
                                                        onSetCard={handleSetCard} 
                                                        onEditMethod={() => handleChangeMethod(null)}
                                                    />
                                                }


                                                {/* CPF */}
                                                {!showPixCode && 
                                                    <section className="flex gap-2 w-full items-center justify-between rounded-lg p-2 px-3 bg-gray-50 text-sm shadow-sm">
                                                        <div className="flex gap-2 items-center w-full">
                                                            <TbId size={20} className="self-start" />

                                                            <div className="flex flex-col w-full">
                                                                <span className="text-gray-400">CPF na nota</span>
                                                                {!showInputCpf && <span className="font-semibold">{cpf ? formatCpf(cpf) : 'Não informado'}</span> }

                                                                {(showInputCpf) &&
                                                                    <div className="flex gap-2 flex-col w-full">
                                                                        <div className="relative flex items-center justify-center w-full">
                                                                            <IMaskInput
                                                                                mask="000.000.000-00"
                                                                                radix=""
                                                                                inputMode="numeric"
                                                                                onAccept={(value) => setCpf(value)}
                                                                                unmask={true} // true|false|'typed'
                                                                                name="observation"
                                                                                value={cpf}
                                                                                placeholder="Opcional"
                                                                                className="
                                                                                    flex w-full rounded-lg
                                                                                    border-0 
                                                                                    mt-0.5
                                                                                    p-2.5 pr-28 
                                                                                    font-extrabold
                                                                                    placeholder:text-gray-400
                                                                                    placeholder:font-normal 
                                                                                    placeholder:text-xs 
                                                                                    ring-1
                                                                                    ring-gray-200
                                                                                    outline-none
                                                                                    focus:ring-custom-primary 
                                                                                    focus:ring-2 
                                                                                    focus:outline-none 
                                                                                    transition-all ease-in-out duration-200"
                                                                            />
                                                                            {cpf && !isCpfValid(cpf) && <span className="absolute right-2 text-shopfood-error">Inválido</span>}
                                                                        </div>
                                                                        <div className="flex gap-1 text-xs">
                                                                            <Button
                                                                                onClick={handleCpf}
                                                                                disabled={!isCpfValid(cpf)}
                                                                                className="text-custom-primary"
                                                                            >Adicionar</Button>
                                                                            <Button
                                                                                onClick={handleCancelCpf}
                                                                                className='self-start'
                                                                            >Cancelar</Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {!showInputCpf &&
                                                            <Button onClick={() => setShowInputCpf(true)} className="text-xs font-semibold leading-4 flex gap-1 items-center justify-between text-xs h-9">
                                                                {!cpf 
                                                                    ? 'Adicionar'
                                                                    : <><TbPencil size={20} />Alterar</>
                                                                }
                                                            </Button>
                                                        }
                                                    </section>
                                                }

                                                {/* OBSERVACAO */}
                                                {!showPixCode && 
                                                    orderType !== 'store_counter' &&
                                                        <section className="flex gap-2 w-full items-center justify-between rounded-lg p-2 px-3 bg-gray-50 text-sm shadow-sm">
                                                            <div className="flex gap-2 items-center w-full">
                                                                <TbMessage size={20} className="self-start" />

                                                                <div className="flex flex-col w-full">
                                                                    <span className="text-gray-400">Observação</span>
                                                                    {!showInputObservation && <span className="font-semibold">{observation ? observation : 'Nenhuma'}</span> }

                                                                    {(showInputObservation) &&
                                                                        <div className="flex gap-2 flex-col w-full">
                                                                            <textarea
                                                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setObservation(e.target.value)}
                                                                                name="observation"
                                                                                value={observation}
                                                                                placeholder="Preencha só o que for do pedido. Obrigado!"
                                                                                className={`
                                                                                    block w-full rounded-md border-0 p-2.5 shadow ring-1 ring-gray-300
                                                                                    placeholder:text-gray-300
                                                                                    placeholder:text-xs
                                                                                    focus:ring-2 focus:ring-custom-primary
                                                                                    transition-all ease-in-out duration-500
                                                                                    ${observation ? 'h-24' : 'h-14'}
                                                                                    focus:h-24
                                                                                    `}
                                                                            />
                                                                            <div className="flex gap-1 text-xs">
                                                                                <Button
                                                                                    onClick={handleObservation}
                                                                                    className="text-custom-primary"
                                                                                    disabled={!observation}
                                                                                >Adicionar</Button>
                                                                                <Button
                                                                                    onClick={handleCancelObservation}
                                                                                    className='self-start'
                                                                                >Cancelar</Button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {!showInputObservation &&
                                                                <Button onClick={() => setShowInputObservation(true)} className="text-xs font-semibold leading-4 flex gap-1 items-center justify-between text-xs h-9">
                                                                {!observation 
                                                                    ? 'Adicionar'
                                                                    : <><TbPencil size={20} />Alterar</>
                                                                }
                                                            </Button>
                                                            }
                                                        </section>
                                                }

                                            </section>
                                        </div>

                                        {(!showRegisterCard && !showPixCode) &&
                                            <div className="hidden md:flex sticky bottom-0 bg-white gap-2 p-4 w-full justify-end">
                                                <ButtonsCheckout
                                                    label={labelBtnPay}
                                                    disabledFinish={!paymentMethod}
                                                    onFinishOrder={() => handleCreateOrder()}
                                                    onEditMethod={() => handleChangeMethod(null)}
                                                    onCancel={() => setShowConfirmCancel(true)}
                                                    showButtonEditMethod={paymentMethod}
                                                    method={paymentMethod}
                                                />
                                            </div>
                                        }
                                    </section>

                                    {!showPixCode && 
                                        <section className={`h-full w-full md:max-w-md md:shadow-xl md:shadow-gray-100 p-2 md:px-0 md:py-4 ${showRegisterCard ? 'pb-20 ' : 'pb-2 '}`}>
                                            {orderType === 'store_counter'
                                                ? <CartStoreCounter onCloseCart={() => {}} />
                                                : <Cart cart={[]} onCloseCart={() => {}} />
                                            }
                                        </section>
                                    }

                                    {/* OCULTANDO BOTAO PARA PAGAMENTO VIA CARTAO ONLINE */}
                                    {(!showRegisterCard && !showPixCode) &&
                                        <div className="absolute w-full flex md:hidden sticky bottom-0 right-0 p-4 gap-2 w-full bg-white">
                                            <ButtonsCheckout 
                                                label={labelBtnPay}
                                                disabledFinish={!paymentMethod} 
                                                onFinishOrder={() => handleCreateOrder()} 
                                                onEditMethod={() => handleChangeMethod(null)}
                                                onCancel={() => setShowConfirmCancel(true)}
                                                showButtonEditMethod={paymentMethod} 
                                                method={paymentMethod} 
                                            />
                                        </div>
                                    }
                                </div>
                }
            </section>

            <Modal open={showConfirmCancel} className="max-w-sm m-4" onClose={() => setShowConfirmCancel(false)}>
                <Confirm 
                    title="Tem certeza?" 
                    message="Deseja realmente sair da tela de pagamento?" 
                    labelConfirm="Sim, quero sair"
                    labelCancel="Não"
                    onConfirm={() => handleCancel()} 
                    onCancel={() => setShowConfirmCancel(false)} 
                />
            </Modal>

        </>
    )
}

export default Checkout;