import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';

// Extensão da interface para incluir a propriedade _retry
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean; // Propriedade opcional
}

export default class BaseService {
  orderType: string;
  token?: string;
  http: AxiosInstance;

  constructor(orderType: string, token: string = '') {
    this.orderType = orderType;
    this.token = token;
    this.http = this.createApiInstance();

    this.http.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig;

        // Verifica se o erro é devido a token expirado
        if (error.response && error.response.status === 401 && originalRequest && !originalRequest._retry) {
          originalRequest._retry = true;

          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }

          try {
            const newToken = await this.refreshToken(token);
            if (newToken) {
              this.token = newToken;
              localStorage.setItem('token', newToken);
              originalRequest.headers['Authorization'] = newToken;
              return this.http(originalRequest);
            }
          } catch (refreshError) {
              return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    )
  }

  createApiInstance(): AxiosInstance {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json'
    };

    if (this.token) {
      headers['Authorization'] = this.token;
    }

    return axios.create({
      baseURL: this.getApiBaseUrl(),
      headers: headers,
    });
  }

  getApiBaseUrl(): string {
    switch (this.orderType) {
      case 'store_counter':
        return 'https://pdv-hml-3.shopfood.io/v2'; //pdv-ss.shopfood.io/v2 //pdv-hml-3.shopfood.io/v2
      default:
        return 'https://api-hml-3.shopfood.io';
    }
  }

  async refreshToken(token: string): Promise<string> {
    const response = await this.http.post('/auth/refresh', {}, {
      headers: {
        Authorization: token
      }
    });
    return response.data.token;
  }

}
