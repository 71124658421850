import { useCartContext } from "../Contexts/CartContext";
import { PRODUCT_TYPE_COMPOSITE } from "./Constants/Product";

//* Vibração para 'confirmar' ações de cliques.
export const vibrate = (pattern: number = 30) => {
    if (navigator.vibrate){
        navigator.vibrate(pattern)
    }
}

export const isPhoneValid = (phone: string) => {
    return Boolean( phone.length && phone.length === 11)
}

export const isCpfValid = (cpf: string) => {
    return Boolean( cpf.length && cpf.length === 11)
}

export const useQuantityProductInCartConference = (productId: number): number => {
    const { cartConference } = useCartContext();

    return cartConference
      .filter((product: any) => (product.id === productId || product.idParentProduct === productId))
      .reduce((total: number, product: any) => {
        //* Soma a quantity do próprio produto, caso seja SIMPLE
        let productTotal = product.type === PRODUCT_TYPE_COMPOSITE ? 0 : product.quantity;

        //* Se o produto tiver steps, somar a quantity de todos os complements
        if (product.steps) {
          const stepQuantity = product.steps.reduce((stepTotal: number, step: any) => {
            return stepTotal + (step.complements?.reduce((complementTotal: number, complement: any) => {
              return complementTotal + complement.quantity;
            }, 0) || 0);
          }, 0);

          //* Soma o total de complements ao total do produto
          productTotal += stepQuantity;
        }

        return total + productTotal;
    }, 0);
}

export const isArraysEqual = (arr1: any, arr2: any): boolean => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

export const normalizeStringToSearch = (value: string): string => {
    return value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const copyToClipboard = async (value: string) => {
    try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            await navigator.clipboard.writeText(value);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = value;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
        }
        return true;
    } catch (err) {
        console.error("Failed to copy: ", err);
        return false;
    }
}

export const hideKeyboard = async () => {
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
}

export interface StoreWorkdays {
    id: number;
    store_id: number;
    service_type: number;
    weekday: number;
    operation_from: string;
    operation_to: string;
    active: boolean;
}

export function generateOperatingHoursText(storeWorkdays: StoreWorkdays[]): string {
    const weekdays: string[] = [
        "Domingo", "Segunda", "Terça",
        "Quarta", "Quinta", "Sexta", "Sábado"
    ];

    let hoursText: string = "";

    storeWorkdays.forEach(service => {
        const day = weekdays[service.weekday];
        hoursText += `<strong>${day}</strong>: ${service.operation_from.slice(0, 5)} até ${service.operation_to.slice(0, 5)}<br />`;
    });

    return hoursText;
}

export const getFirstAndLastDayOfMonth = (year?: number, month?: number) => {
    const currentDate = new Date();
    const currentYear = year || currentDate.getFullYear();
    const currentMonth = month || (currentDate.getMonth() + 1); // getMonth() retorna de 0 a 11
    const firstDay = new Date(currentYear, currentMonth - 1, 1);
    const lastDay = new Date(currentYear, currentMonth, 0);

    // 'YYYY-MM-DD'
    const formatDate = (date: Date) => date.toISOString().split('T')[0];

    return {
        date_from: formatDate(firstDay),
        date_to: formatDate(lastDay),
    };
}

export const getPercentageDiscount = (price: number, priceFrom: number): number => {
    if (priceFrom === 0) {
        throw new Error("priceFrom não pode ser zero.");
    }
    const percent = ((price - priceFrom) / priceFrom) * 100;

    return Math.round(percent * 100) / 100 ;
}


export const compareEquals = (obj1: any, obj2: any) => {
    // Verifica se são o mesmo tipo de dado
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    // Verifica se possuem o mesmo número de chaves
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    // Verifica se cada chave tem o mesmo valor
    for (let key of keys1) {
        if (!compareEquals(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const convertDateToTimestamp = (dateString: string) => {
    //*dateString no formato '23/10/2024 17:43 UTC -03:00'
    const formattedDateString = dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
    const date = new Date(formattedDateString);
    return date.getTime();
}

export const getWeekdayInPortuguese = (weekday: number, abbreviated: boolean = false): string => {
    const weekdays = abbreviated 
                        ? ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb']
                        : ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'];
    return weekdays[weekday];
};
